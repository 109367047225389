import { proxy } from 'valtio';
/** 部位持有中排序組別 */
export const useSortVirtualHoldingDataStore = proxy({
    sortKey: 'lastModifiedDatetime',
    orderKey: 'desc',
});
/** 產業排序組別 */
export const useSortSignalrIndustryDataStore = proxy({
    sortKey: '',
    orderKey: 'desc',
});
/** 一般排序組別1 */
export const useSortSignalrDataStore = proxy({
    sortKey: '',
    orderKey: 'desc',
});
/** 一般排序組別2 */ //這不會抽 因為需要建立一個array去存放quote
export const useSortSignalrDataStore2 = proxy({
    sortKey: '',
    orderKey: 'desc',
});
/** 一般排序組別3 */ //這不會抽 因為需要建立一個array去存放quote
export const useSortSignalrDataStore3 = proxy({
    sortKey: '',
    orderKey: 'desc',
});
